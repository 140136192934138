import { useCallback, useState } from 'react';
import { FeedbackToProduct } from 'site/graphql';

export type IUseReviewModalFeedback = Pick<
    FeedbackToProduct,
    'id' | 'accountName' | 'date' | 'dateUpdate' | 'rating' | 'description'
>;

const useReviewModal = () => {
    const [isReadMoreModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedFeedbackToProduct, setFeedbackToProduct] = useState<IUseReviewModalFeedback>(null);

    const handleModalClose = useCallback(() => setModalOpen(false), []);
    const handleModalOpen = useCallback((feedbackToProduct: IUseReviewModalFeedback) => {
        setModalOpen(true);
        setFeedbackToProduct(feedbackToProduct);
    }, []);

    return [isReadMoreModalOpen, selectedFeedbackToProduct, handleModalOpen, handleModalClose] as const;
};

export default useReviewModal;
