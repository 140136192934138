const separatorReducer = separator => (acc: any[], elem) => (!acc ? [elem] : [...acc, separator, elem]);

/**
 * Функция используется для рендера массива элементов через разделитель, например `, `(запятую)
 * Пример
 * separatedElements(['Вася', 'Петя'], (name) => <span key={name}>{name}</span>)
 * венрет <span key="Вася">Вася</span>, <span key="Петя">Петя</span>
 * @param array массив элементов
 * @param mapper ф-я преобразования элемента
 * @param separator разделитель, может быть строкой или react элементом
 */
const separatedElements = <T>(array: T[], mapper: (item: T) => any, separator = ', '): any[] => {
    if (!array) return null;
    return array.map(mapper).reduce(separatorReducer(separator), null as any[]);
};

export default separatedElements;
