import Modal from 'core/components/Modal';
import { ieFix } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { IUseReviewModalFeedback } from 'site/components/reviews/hooks/useReviewModal';
import FeedbackHeader from './FeedbackHeader';

type IFeedbackDescriptionModalProps = {
    isOpen: boolean;
    onCloseModal(): void;
    feedbackToProduct: IUseReviewModalFeedback;
} & WithStyles<typeof styles>;

const FeedbackDescriptionModal: React.FC<IFeedbackDescriptionModalProps> = ({
    classes,
    isOpen,
    onCloseModal,
    feedbackToProduct,
}) => (
    <Modal label="Просмотр отзыва" open={isOpen} onClose={onCloseModal}>
        <div className={classes.container}>
            {feedbackToProduct && (
                <>
                    <FeedbackHeader feedbackToProduct={feedbackToProduct} />
                    <div className={classes.description}>{feedbackToProduct.description}</div>
                </>
            )}
        </div>
    </Modal>
);

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            backgroundColor: 'white',
            padding: 1 + 'rem',
            width: 90 + 'vw',
            maxHeight: 80 + 'vh',
            overflowY: 'auto',

            ...ieFix({ display: 'block' }),

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 2rem)', // весь экран - 2*padding
                height: 'calc(100vh - 2rem)', // весь экран - 2*padding
                justifyContent: 'center',
                maxHeight: 'none',
            },
        },
        description: {
            whiteSpace: 'pre-line',
            fontSize: 0.9 + 'rem',
        },
    });

export default injectSheet(styles)(FeedbackDescriptionModal);
