import CenterLoader from 'core/components/common/CenterLoader';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';
import AuthForm from './auth-form/AuthForm';
import { useAuth } from './AuthProvider';

const AuthWindow: React.FC<WithStyles<typeof styles>> = ({ children, classes }) => {
    const { loading, isLoggedIn } = useAuth();

    if (loading) {
        return <CenterLoader absolute />;
    }

    if (isLoggedIn) {
        return <>{children}</>;
    }

    return (
        <div className={classes.window}>
            <AuthForm showRememberMe showSocialLogin />
        </div>
    );
};

const styles = createStyles({
    window: {
        display: 'flex',
        justifyContent: 'center',
    },
});

export default injectSheet(styles, 'AuthWindow')(AuthWindow);
