import { ApolloError, gql } from 'apollo-boost';
import { showSnackbar } from 'core/components/snackbar';
import { getErrors } from 'core/utils/apollo';
import { useCallback } from 'react';
import {
    FeedbackToProductOrderEnum,
    GetFeedbackProductQuery,
    useCreateFeedbackToProductMutation,
    useGetFeedbackProductQuery,
    useGetFeedbacksToProductLazyQuery,
    useUpdateFeedbackToProductMutation,
} from 'site/graphql';

gql`
    query GetFeedbackProduct($id: Int!) {
        product(id: $id) {
            id
            hasFeedback
            hasAnyPurchasedForm
        }
    }
`;

gql`
    mutation CreateFeedbackToProduct($feedbackToProduct: FeedbackToProductPublicInsertInput!) {
        createFeedbackToProduct(feedbackToProduct: $feedbackToProduct) {
            id
        }
    }
`;

gql`
    mutation UpdateFeedbackToProduct($feedbackToProduct: FeedbackToProductPublicUpdateInput!) {
        updateFeedbackToProduct(feedbackToProduct: $feedbackToProduct) {
            id
        }
    }
`;

const useFeedbackButtonData = (productId: number) => {
    const { data, refetch: refetchProduct } = useGetFeedbackProductQuery({ variables: { id: productId } });
    const product = (data && data.product) || ({} as GetFeedbackProductQuery['product']);

    const [refreshList] = useGetFeedbacksToProductLazyQuery({
        fetchPolicy: 'network-only',
        variables: {
            filter: {
                order: FeedbackToProductOrderEnum.Date,
                direction: 'desc',
                productId,
                full: true,
                onlyVisible: false,
            },
        },
    });

    const [createFeedbackMutation] = useCreateFeedbackToProductMutation({
        onCompleted: () => {
            showSnackbar({});
            refetchProduct();
            refreshList();
        },
        onError: (error: ApolloError) => showSnackbar({ snackbarErrors: getErrors(error) }),
    });

    const createFeedback = useCallback(
        (description: string, rating: number) => {
            createFeedbackMutation({ variables: { feedbackToProduct: { description, productId, rating } } });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productId],
    );

    const [updateFeedbackMutation] = useUpdateFeedbackToProductMutation({
        onCompleted: () => {
            showSnackbar({});
            refreshList();
        },
        onError: (error: ApolloError) => showSnackbar({ snackbarErrors: getErrors(error) }),
    });

    const updateFeedback = useCallback(
        (description: string, rating: number) => {
            updateFeedbackMutation({ variables: { feedbackToProduct: { description, productId, rating } } });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [productId],
    );

    return [product, createFeedback, updateFeedback, refetchProduct] as const;
};

export default useFeedbackButtonData;
