import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatToLocalDayMonthYearDot } from 'core/utils';
import React from 'react';
import { FeedbackToProduct } from 'site/graphql';
import StarRating from './StarRating';

type IFeedbackHeaderProps = {
    feedbackToProduct: Pick<FeedbackToProduct, 'id' | 'accountName' | 'date' | 'dateUpdate' | 'rating'>;
} & WithStyles<typeof styles>;

const FeedbackHeader: React.FC<IFeedbackHeaderProps> = ({
    classes,
    feedbackToProduct: { accountName, date, dateUpdate, rating },
}) => (
    <div className={classes.container}>
        {accountName}, {formatToLocalDayMonthYearDot(date)}
        {dateUpdate && ` (обновлен ${formatToLocalDayMonthYearDot(dateUpdate)})`}
        <StarRating
            starsSelected={rating}
            isSelectionDisabled
            classname={classes.rating}
            starsCustomStyles={{
                height: 23,
                width: 23,
                cursor: 'default',
                marginRight: 6,
            }}
        />
    </div>
);

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            fontSize: 1.1 + 'rem',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            marginBottom: 0.5 + 'rem',

            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                textAlign: 'center',
            },
        },
        rating: {
            display: 'inline-block',
            marginLeft: 20,

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
    });

export default injectSheet(styles)(FeedbackHeader);
