import React from 'react';

type IInlineEditProps = {
    text: string;
    formatter?: (value: string) => string;
    change: (text: string) => void;
    placeholder?: string;
    className?: string;
    activeClassName?: string;
    minLength?: number;
    maxLength?: number;
    validate?: (text: string) => boolean;
    styleStatic?: React.CSSProperties;
    styleEditing?: React.CSSProperties;
    tabIndex?: number;
    isDisabled?: boolean;
    editing?: boolean;
    stopPropagation?: boolean;
    inputMode?: React.HTMLAttributes<any>['inputMode'];
};

// https://github.com/kaivi/ReactInlineEdit
class InlineEdit extends React.Component<IInlineEditProps> {
    static defaultProps = {
        minLength: 1,
        maxLength: 256,
        tabIndex: 0,
        isDisabled: false,
        editing: false,
    };

    state = {
        editing: this.props.editing,
        text: this.props.text,
        minLength: this.props.minLength,
        maxLength: this.props.maxLength,
    };

    input = React.createRef<HTMLInputElement>();

    UNSAFE_componentWillMount() {
        this.isInputValid = this.props.validate || this.isInputValid;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const isTextChanged = nextProps.text !== this.props.text;
        const isEditingChanged = nextProps.editing !== this.props.editing;
        const nextState = {} as any;
        if (isTextChanged) {
            nextState.text = nextProps.text;
        }
        if (isEditingChanged) {
            nextState.editing = nextProps.editing;
        }
        if (isTextChanged || isEditingChanged) {
            this.setState(nextState);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const inputElem = this.input.current;

        if (this.state.editing && !prevState.editing) {
            inputElem.focus();
            inputElem.setSelectionRange(0, inputElem.value.length);
        } else if (this.state.editing && prevProps.text !== this.props.text) {
            this.finishEditing();
        }
    }

    startEditing = e => {
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({ editing: true, text: this.props.text });
    };

    finishEditing = () => {
        if (this.isInputValid(this.state.text) && this.props.text !== this.state.text) {
            this.commitEditing();
        } else if (this.props.text === this.state.text || !this.isInputValid(this.state.text)) {
            this.cancelEditing();
        }
    };

    cancelEditing = () => {
        this.setState({ editing: false, text: this.props.text });
    };

    commitEditing = () => {
        this.setState(
            {
                editing: false,
                text: this.state.text,
            },
            () => this.props.change(this.state.text),
        );
    };

    clickWhenEditing = e => {
        if (this.props.stopPropagation) {
            e.stopPropagation();
        }
    };

    isInputValid = text => {
        return text.length >= this.state.minLength && text.length <= this.state.maxLength;
    };

    keyDown = event => {
        if (event.keyCode === 13) {
            this.finishEditing();
        } else if (event.keyCode === 27) {
            this.cancelEditing();
        }
    };

    textChanged = event => {
        this.setState({
            text: event.target.value.trim(),
        });
    };

    render() {
        const formatter = this.props.formatter || (val => val);

        if (this.props.isDisabled) {
            return (
                <span className={this.props.className} style={this.props.styleStatic}>
                    {this.state.text || this.props.placeholder}
                </span>
            );
        } else if (!this.state.editing) {
            return (
                <span
                    className={this.props.className}
                    onFocus={this.startEditing}
                    onClick={this.startEditing}
                    tabIndex={this.props.tabIndex}
                    style={this.props.styleStatic}
                    title="Редактировать"
                >
                    {formatter(this.state.text || this.props.placeholder)}
                </span>
            );
        } else {
            return (
                <input
                    onClick={this.clickWhenEditing}
                    onKeyDown={this.keyDown}
                    onBlur={this.finishEditing}
                    className={this.props.activeClassName}
                    placeholder={this.props.placeholder}
                    defaultValue={this.state.text}
                    onChange={this.textChanged}
                    style={this.props.styleEditing}
                    inputMode={this.props.inputMode}
                    ref={this.input}
                />
            );
        }
    }
}

export default InlineEdit;
