import FeedbackDescription from 'site/components/feedback-product/FeedbackDescription';
import FeedbackDescriptionModal from 'site/components/feedback-product/FeedbackDescriptionModal';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { FeedbackToProduct, Product } from 'site/graphql';
import useReviewModal from './hooks/useReviewModal';
import ReviewProductSection from './ReviewProductSection';

type IFeedback = Pick<FeedbackToProduct, 'id' | 'accountName' | 'description' | 'date'> & {
    product?: Pick<Product, 'id' | 'name'>;
};

type IReviewsTableProps = {
    feedbacks: IFeedback[];
} & WithStyles<typeof styles>;

const ReviewsTable: React.FC<IReviewsTableProps> = ({ classes, feedbacks }) => {
    const [isReadMoreModalOpen, selectedFeedbackToProduct, handleModalOpen, handleModalClose] = useReviewModal();

    return (
        <>
            <table className={classes.table}>
                <tbody>
                    {feedbacks.map(feedback => (
                        <tr key={feedback.id}>
                            <td>
                                <b>{feedback.accountName}</b>
                            </td>
                            <td>
                                {/* Этот div необходим для правильно расчета ширины react-truncate (иначе не учитывается padding) */}
                                <div>
                                    <FeedbackDescription feedbackToProduct={feedback} onReadMore={handleModalOpen} />
                                </div>
                            </td>
                            <td>
                                <ReviewProductSection feedback={feedback} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <FeedbackDescriptionModal
                feedbackToProduct={selectedFeedbackToProduct}
                onCloseModal={handleModalClose}
                isOpen={isReadMoreModalOpen}
            />
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        table: {
            borderCollapse: 'collapse',
            marginBottom: 15,
            color: theme.colors.contrastBg,

            '& td': {
                verticalAlign: 'top',
                padding: '30px 30px 0 0',
                fontSize: 0.9 + 'rem',

                [theme.breakpoints.down('xs')]: {
                    display: 'block',
                    padding: 0,
                },
            },

            '& td:first-child': {
                width: 15 + '%',

                [theme.breakpoints.down('xs')]: {
                    width: 100 + '%',
                },
            },

            '& td:nth-child(2)': {
                width: 65 + '%',

                [theme.breakpoints.down('xs')]: {
                    width: 100 + '%',
                },
            },

            '& td:last-child': {
                paddingRight: 0,
                width: 20 + '%',

                [theme.breakpoints.down('xs')]: {
                    width: 100 + '%',
                    paddingTop: 1 + 'rem',
                    paddingBottom: 3 + 'rem',
                },
            },
        },
    });

export default injectSheet(styles)(ReviewsTable);
