import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useCallback } from 'react';
import Truncate from 'react-truncate';
import { IUseReviewModalFeedback } from 'site/components/reviews/hooks/useReviewModal';
import { FeedbackToProduct } from 'site/graphql';

type IFeedbackDescriptionProps = {
    feedbackToProduct: Pick<FeedbackToProduct, 'id' | 'description'>;
    onReadMore(feedbackToProduct: IUseReviewModalFeedback): void;
} & WithStyles<typeof styles>;

const FeedbackDescription: React.FC<IFeedbackDescriptionProps> = ({ feedbackToProduct, onReadMore, classes }) => {
    const onReadMoreClick = useCallback(
        (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            onReadMore(feedbackToProduct);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [feedbackToProduct],
    );

    return (
        <Truncate
            lines={6}
            ellipsis={
                <span>
                    ...{' '}
                    <a href="#" onClick={onReadMoreClick} className={classes.readMoreText}>
                        Читать полностью
                    </a>
                </span>
            }
            // https://github.com/pablosichert/react-truncate/pull/130
            width={null}
        >
            {feedbackToProduct.description.split(/\r?\n/).map((line, i, arr) => {
                const result = <span key={i}>{line}</span>;

                if (i === arr.length - 1) {
                    return result;
                } else {
                    return [result, <br key={i + 'br'} />];
                }
            })}
        </Truncate>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        readMoreText: {
            color: theme.colors.black,
            fontWeight: 'bold',
            textDecoration: 'none',
        },
    });

export default injectSheet(styles)(FeedbackDescription);
