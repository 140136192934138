import CustomCheckbox from 'core/components/CustomCheckbox';
import propsFromUrl from 'core/enhancers/propsFromUrl';
import { WithStyles, ITheme } from 'core/style/interfaces';
import { isMobile } from 'core/utils';
import React from 'react';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { compose } from 'recompose';
import { Tag } from 'site/graphql';
import dropDownHoc, { styles as dropdownStyles } from '../../../core/components/dropdown/dropDownHoc';
import dropDownSelfStateHoc from '../../../core/components/dropdown/dropDownSelfStateHoc';
import DropDownTagsFilterButton from './DropDownTagsFilterButton';

const DropDown = dropDownSelfStateHoc({
    DropDown: dropDownHoc({
        DropDownButton: DropDownTagsFilterButton,
    }),
});

const styles = (theme: ITheme) =>
    createStyles({
        dropdown: {
            ...dropdownStyles.dropdown,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontSize: 0.9 + 'rem',
            border: '1px solid #cccccc',
            cursor: 'pointer',
            height: 42,

            [theme.breakpoints.down('xs')]: {
                height: 45,
            },
        },
        dropdownList: {
            ...dropdownStyles.dropdownList,
            width: 370,
            height: 500,
            left: -1,
            padding: 20,
            boxSizing: 'border-box',
            border: '1px solid #cccccc',
            background: 'white',
            overflowY: 'auto',

            [theme.breakpoints.down('xs')]: {
                width: 320,
                left: 'auto',
                right: -1,
                padding: '0 20px',

                [theme.breakpoints.down(350)]: {
                    width: 'calc(100vw - 30px)',
                },
            },
        },
        mobileSeporator: {
            borderBottom: '1px solid #cccccc',
            marginLeft: -20,
            marginRight: -20,

            '&:last-child': {
                borderBottom: 'none',
            },
        },
        resetButtonContainer: {
            display: 'flex',
            paddingTop: 15,
            paddingBottom: 15,
            justifyContent: 'center',
        },
        resetButton: {
            fontSize: 0.9 + 'rem',
            border: '1px solid #cccccc',
            fontWeight: 600,
            padding: '10px 30px',
        },
    });

class DropDownTagsFilter extends React.PureComponent<IInnerProps> {
    getSelectedTags() {
        const { urlTags } = this.props;
        return urlTags ? (Array.isArray(urlTags) ? urlTags : [urlTags]) : [];
    }

    handleChange = (tagId: string) => (checked: boolean) => {
        let selectedTags = this.getSelectedTags();

        if (checked) {
            selectedTags.push(tagId);
        } else {
            selectedTags = selectedTags.filter(tag => tag !== tagId);
        }

        this.props.onFilterChange({
            tags: selectedTags.length === 0 ? undefined : selectedTags,
        });
    };

    onReset = () => {
        this.props.onFilterChange({
            tags: undefined,
        });
    };

    render() {
        const { classes, tags, urlTags } = this.props;

        return (
            <DropDown
                classes={{
                    dropdown: classes.dropdown,
                    dropdownList: classes.dropdownList,
                }}
                id="filter-tags"
            >
                {tags.map(tag => (
                    <React.Fragment key={tag.id}>
                        <CustomCheckbox
                            onChange={this.handleChange(tag.id.toString())}
                            checked={this.getSelectedTags().includes(tag.id.toString())}
                            label={tag.name}
                        />
                        {isMobile() && <div className={classes.mobileSeporator} />}
                    </React.Fragment>
                ))}
                {isMobile() && urlTags && (
                    <div className={classes.resetButtonContainer}>
                        <div className={classes.resetButton} onClick={this.onReset}>
                            Сбросить
                        </div>
                    </div>
                )}
            </DropDown>
        );
    }
}

export type IOutterProps = {
    tags: Tag[];
    onFilterChange: (newFilter) => void;
};

export type IInnerProps = {
    urlTags: string[] | string;
} & IOutterProps &
    WithStyles<typeof styles>;

export default compose<IInnerProps, IOutterProps>(
    propsFromUrl({ query: [{ tags: 'urlTags' }] }),
    injectSheet(styles),
)(DropDownTagsFilter);
