import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';

type ISwipeIconProps = {} & WithStyles<typeof styles>;

const SwipeIcon: React.FC<ISwipeIconProps> = ({ classes }) => {
    return (
        <div className={classes.swipeIconWrap}>
            <div className={classes.swipeIcon} />
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        swipeIconWrap: {
            display: 'flex',
            justifyContent: 'flex-end',

            [theme.breakpoints.up(768)]: {
                display: 'none',
            },
        },
        swipeIcon: {
            animation: 'moveSwipeIcon 1.4s ease-in-out infinite',
            animationName: '$moveSwipeIcon',
            backgroundImage: `url(${require('./swipe.png')})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: 22,
            height: 24,
            opacity: 0.5,
        },
        '@keyframes moveSwipeIcon': {
            '0%': {
                marginRight: 0,
            },

            '50%': {
                marginRight: 20,
            },

            '100%': {
                marginRight: 0,
            },
        },
    });

export default injectSheet(styles)(SwipeIcon);
