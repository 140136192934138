import { column, row } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { isFilledArray, isMobile, skip, take } from 'core/utils';
import { excludeOfferWithoutForms } from 'core/utils/product-forms/filterProductForms';
import React from 'react';
import MediumFilter from 'site/components/courses-filters/MediumFilter';
import { IProductFormCardProps } from 'site/components/product-form/ProductFormCard';
import { GetCourseListQuery } from 'site/graphql';
import LoadMoreButton from '../../../core/components/LoadMoreButton';
import DefaultNotFound from './DefaultNotFound';
import ProductFormCard from './ProductFormCard';

type IProductFormListProps = {
    productForms: GetCourseListQuery['productForms']['result'];
    refetch: any;
    isFetching?: boolean;
    isDark?: boolean;
    mode?: IProductFormCardProps['mode'];
    extraClasses?: any;
    changeFilter: (newFilter) => void;
    isAllItemsDownloaded: boolean;
    loadMore: () => void;
    NotFoundComponent?: React.ComponentType<{}>;
} & WithStyles<typeof styles>;

class ProductFormList extends React.Component<IProductFormListProps> {
    public static defaultProps: Partial<IProductFormListProps> = {
        isDark: false,
        mode: 'FULL',
        productForms: [],
        NotFoundComponent: DefaultNotFound,
    };

    render() {
        const {
            classes,
            extraClasses,
            productForms,
            isDark,
            mode,
            isFetching,
            changeFilter,
            isAllItemsDownloaded,
            loadMore,
            NotFoundComponent,
        } = this.props;
        const cls = { ...classes, ...extraClasses };
        const filteredValues = excludeOfferWithoutForms(productForms);
        const upperBlockCardCount = isMobile() ? 3 : 6;
        const upperBlock = take(upperBlockCardCount)(filteredValues);
        const lowerBlock = skip(upperBlockCardCount)(filteredValues);

        return (
            <div className={cls.list}>
                {upperBlock.map(productForm => (
                    <div className={cls.item} key={productForm.id}>
                        <ProductFormCard productForm={productForm} isDark={isDark} mode={mode} />
                    </div>
                ))}

                {lowerBlock.length !== 0 && <MediumFilter onSlideChanged={changeFilter} />}

                {lowerBlock.map(productForm => (
                    <div className={cls.item} key={productForm.id}>
                        <ProductFormCard productForm={productForm} isDark={isDark} mode={mode} />
                    </div>
                ))}

                <LoadMoreButton
                    isAllItemsDownloaded={isAllItemsDownloaded}
                    isFetching={isFetching}
                    loadMore={loadMore}
                    values={productForms}
                />

                {!isFetching && !isFilledArray(productForms) && <NotFoundComponent />}
            </div>
        );
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        list: {
            ...row(),
        },
        item: {
            ...column(),
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '1.5rem',
            [theme.breakpoints.up('sm')]: {
                ...column({ cols: 6 }),
            },
            [theme.breakpoints.up('md')]: {
                ...column({ cols: 4 }),
            },
        },
    });

export default injectSheet(styles, 'ProductFormList')(ProductFormList);
