import { HighLowIndex } from '../high-low/const';
import { numberSeparateThousands } from 'core/utils';

export const dividendSharesFilterOptions: { value: HighLowIndex; label: string }[] = [
    { value: HighLowIndex.MoscowExchangesIndex, label: 'Индекс МосБиржи' },
    { value: HighLowIndex.MoscowExchangeBlueChipIndex, label: 'Индекс Голубых фишек' },
    { value: undefined, label: 'Все' },
];

export const numberFormatByValue = (value: number) => {
    switch (true) {
        case value < 10:
            return value.toFixed(4);
        case value > 10000:
            return numberSeparateThousands(value.toFixed(0));
        default:
            return numberSeparateThousands(value.toFixed(2));
    }
};
