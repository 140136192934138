import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { IService } from './hooks/useServices';
import { Link } from 'react-router-dom';
import { card } from 'core/style';

interface IServiceCardProps extends WithStyles<typeof styles> {
    service: IService;
}

const ServiceCard: React.FC<IServiceCardProps> = ({ service: { icon, href, title, description, linkTo, isOpenNewTab }, classes }) => {
    const OpenNewTab = isOpenNewTab ? '_blank' : null;
    const relTab = isOpenNewTab ? 'noopener noreferrer': null;
    return (
        <div className={classes.mainWrap}>
                {linkTo ? (
                    <a href={href} className={classes.titleLink} target={OpenNewTab} rel={relTab}>
                        <div className={classes.main}>
                            <img src={icon} alt={title} className={classes.icon} />
                            <div className={classes.title}>{title}</div>
                            <div className={classes.description}>{description}</div>
                        </div>
                    </a>
                )
                : (
                    <Link to={href} className={classes.titleLink}>
                        <div className={classes.main}>
                            <img src={icon} alt={title} className={classes.icon} />
                            <div className={classes.title}>{title}</div>
                            <div className={classes.description}>{description}</div>
                        </div>
                    </Link>
                )}
            </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        mainWrap: {
            width: 350,
            [theme.breakpoints.down(350)]: {
                width: 300,
            },
            marginTop: 10,
        },
        main: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            ...card(theme),
        },
        icon: {
            alignSelf: 'flex-end',
            margin: '10px 30px 0 0',
            objectFit: 'contain',
            width: 180,
            height: 180,

            [theme.breakpoints.down('sm')]: {
                width: 140,
                height: 140,
            },
        },
        title: {
            margin: '0px 30px 0px 15px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: '1.3rem',

            [theme.breakpoints.down('sm')]: {
                fontSize: '1.125rem',
            },
        },
        titleLink: {
            color: '#000000',
            textDecoration: 'none',
            cursor: 'pointer',
        },
        description: {
            margin: '18px 30px 0px 15px',
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            fontSize: 0.85 + 'rem',
            lineHeight: '1rem',
            color: theme.colors.textLight,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
            whiteSpace: 'pre-line',
        },
    });

export default injectSheet(styles)(ServiceCard);
