import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { Children, FC } from 'react';
import { useDeviceInfo } from '../DeviceInfoContext';
import useMedia from './hooks/useMedia';

type CarouselNewProps = WithStyles<typeof styles> & {
    naturalSlideWidth?: number;
    naturalSlideHeight?: number;
    isPlaying?: boolean;
};

const CarouselNew: FC<CarouselNewProps> = ({
    classes,
    children,
    naturalSlideWidth = 1,
    naturalSlideHeight = 1,
    isPlaying = true,
}) => {
    const visibleSlides = useMedia([3, 3, 3, 2, 1.15]);
    const slideWidth = useMedia([1, 1, 1, 1, 1]);
    const slideHeight = useMedia([1, 1, 1.2, 1.05, 1.3]);
    const { isSSR, isTablet, isMobile } = useDeviceInfo();
    const slides = Children.toArray(children).filter(slide => Boolean(slide));
    const totalSlides = slides.length;

    // При SSR и числе элементов < 4 (для мобильных < 2) отрисовываем обычный блок
    const showCarousel = !isSSR && totalSlides > (isTablet || isMobile ? 1 : 3);

    return showCarousel ? (
        <CarouselProvider
            visibleSlides={visibleSlides}
            totalSlides={totalSlides}
            naturalSlideWidth={naturalSlideWidth * slideWidth}
            naturalSlideHeight={naturalSlideHeight * slideHeight}
            isPlaying={isPlaying}
            className={classes.carouselProviderAll}
            dragEnabled={false}
            infinite
        >
            <div className={classes.container}>
                <Slider className={classes.slider}>
                    {slides.map((slide, index) => (
                        <Slide
                            index={index}
                            key={index}
                            className={classes.slide}
                            classNameVisible={classes.slideVisible}
                            classNameHidden={classes.slideHidden}
                        >
                            <div className={classes.slideChild}>{slide}</div>
                        </Slide>
                    ))}
                </Slider>
                <ButtonBack className={c(classes.button, classes.buttonBack)}>Назад</ButtonBack>
                <ButtonNext className={c(classes.button, classes.buttonNext)}>Вперед</ButtonNext>
            </div>
        </CarouselProvider>
    ) : (
        <div className={classes.ssrContainer}>
            {slides.slice(0, 3).map((slide, index) => (
                <div key={index} className={classes.ssrSlide}>
                    {slide}
                </div>
            ))}
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        carouselProviderAll: {
            /* Запрет скролла страницы, если мы начали двигать слайдер по оси X */
            touchAction: 'pan-y',
        },
        container: {
            position: 'relative',
            height: 'auto',
            width: 100 + '%',

            '&:hover $buttonBack, &:hover $buttonNext': {
                visibility: 'visible',
                opacity: 0.9,
            },
        },
        slide: {},
        slider: {},
        slideHidden: {},
        slideVisible: {},
        slideChild: {
            boxSizing: 'border-box',
            padding: '10px 0px',
            display: 'flex',
            justifyContent: 'center',
            height: 100 + '%',
            margin: '0px 20px',
        },
        button: {
            position: 'absolute',
            width: 40,
            height: 40,
            top: 50 + '%',
            transform: 'translateY(-50%)',
            borderRadius: '50%',
            border: `2px solid ${theme.colors.lightGray}`,
            background: 'white',
            boxShadow: '0px 2px 2px rgba(0,0,0,0.2)',
            opacity: 0,
            visibility: 'hidden',
            fontSize: 0,
            transition: 'visibility 0.3s, opacity 0.2s linear',

            '&::after': {
                content: '""',
                border: 'solid #3f4647',
                opacity: 0.5,
                borderWidth: '0px 4px 4px 0px',
                display: 'inline-block',
                padding: 4,
            },

            '&:hover': {
                '&:after': {
                    opacity: 1,
                },
            },
        },
        buttonBack: {
            left: 0,

            '&::after': {
                marginLeft: 4,
                transform: 'rotate(135deg)',
            },
        },
        buttonNext: {
            right: 0,

            '&::after': {
                marginRight: 4,
                transform: 'rotate(-45deg)',
            },
        },
        ssrContainer: {
            display: 'flex',
        },
        ssrSlide: {
            boxSizing: 'border-box',
            width: 350,
            [theme.breakpoints.down(1220)]: {
                width: 300,
            },
            [theme.breakpoints.down(350)]: {
                width: 250,
            },
            padding: '10px 0px',
            display: 'flex',
            justifyContent: 'center',
            margin: '0px 20px',
        },
    });

export default injectSheet(styles, 'CarouselNew')(CarouselNew);
