import { ApolloError } from 'apollo-boost';
import { title } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import { ifBrowser } from 'core/utils';
import { getErrors } from 'core/utils/apollo';
import React, { Fragment } from 'react';
import AuthForm from 'site/components/auth/auth-form/AuthForm';
import { useAuth } from 'site/components/auth/AuthProvider';
import CenterLoader from './CenterLoader';
import NotFound from './NotFound';

type IErrorsShowerProps = {
    loading: boolean;
    customNotFoundContent?: JSX.Element;
    error: ApolloError;
} & WithStyles<typeof styles>;

const ErrorsShower: React.FC<IErrorsShowerProps> = ({ classes, error, children, loading, customNotFoundContent }) => {
    // ifBrowser() нужен, чтобы preloader не блокировал сбор промисов с дочерних компонентов при SSR
    if (loading && ifBrowser()) {
        return (
            <div className={classes.root}>
                <CenterLoader />
            </div>
        );
    }

    const errors = getErrors(error);
    const firstErrorWithCode = errors && errors.find(err => err.extensions && err.extensions.code);
    const code = firstErrorWithCode && firstErrorWithCode.extensions && firstErrorWithCode.extensions.code;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isLoggedIn } = useAuth();

    if (code === 'forbid-error' && !isLoggedIn) {
        return (
            <div className={classes.root}>
                <AuthForm showRememberMe showSocialLogin />
            </div>
        );
    }

    if (code === 'not-found-error' || code === 'forbid-error') {
        return (
            <div className={classes.root}>
                <NotFound customContent={customNotFoundContent} />
            </div>
        );
    }

    if (code === 'internal-error' || code === 'bad-request-error') {
        return (
            <div className={classes.root}>
                <h1 className={classes.title}>Упс ಠ_ಠ</h1>
                <p className={classes.message}>Что-то пошло не так, команда разработчиков уже выехала!</p>
            </div>
        );
    }

    return <Fragment>{children}</Fragment>;
};

const styles = createStyles({
    root: {
        minHeight: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        ...title({ size: 'h1' }),
    },
    message: {},
});
export default injectSheet(styles, 'ErrorsShower')(ErrorsShower);
