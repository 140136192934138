import { compose, withProps } from 'recompose';
import { withRouter, RouteComponentProps } from 'react-router';
import { parseLocationQuery } from 'core/utils';

type IQueryDefinition = string | { [key: string]: ((value: string) => any) | string } | ((query) => any);

type IPropsFromUrl = {
    areas?: IQueryDefinition[];
    query?: IQueryDefinition[];
};

const definitionReducer = urlDataParams => (result: { [key: string]: any }, definition: IQueryDefinition) => {
    if (typeof definition === 'string') {
        result[definition] = urlDataParams[definition];
        return result;
    }

    if (typeof definition === 'object') {
        const keys = Object.keys(definition);
        const key = keys[0];
        const transformer = definition[key];

        if (typeof transformer === 'string') {
            result[transformer] = urlDataParams[key];
        }
        if (typeof transformer === 'function') {
            result[key] = transformer(urlDataParams[key]);
        }

        return result;
    }

    if (typeof definition === 'function') {
        return { ...result, ...definition(urlDataParams) };
    }

    return result;
};

/**
 * Функция для извлечения параметров из урла и прокидываения их как props
 * пример
 * propsFromUrl({
 *  areas: [
 *      'productId',
 *      'formId',
 *  ],
 *  query: [
 *      'order',
 *      { take: (v) => parseInt(v) || 9 },
 *      { skip: (v) => parseInt(v) || 0 },
 *      { tags: (value) => value.split(',') },
 *      { complexity: 'slozhnost' },
 *      (query) => ({ currentPage: calcPage(query) })
 *  ]
 * })
 * в результате в компонент будут переданы следующие props: productId, formId, take, skip, tags, complexity, currentPage
 */
const propsFromUrl = <TProps = any>({ areas = [], query = [] }: IPropsFromUrl) =>
    compose(
        withRouter,
        withProps<TProps, RouteComponentProps<any>>(({ match, location }) => {
            return {
                ...(areas.reduce(definitionReducer(match.params), {}) as any),
                ...(query.reduce(definitionReducer(parseLocationQuery(location)), {}) as any),
            };
        }),
    );

export default propsFromUrl;
