import { SettingsContext } from 'core/components/settingsContext';
import { useCallback, useContext, useMemo, useState } from 'react';

type IUseFetchOnButtonArgs = {
    loading: boolean;
    defaultTake: number;
    defaultSkip: number;
    count: number;
    values: any[];
    fetch: (take: number, skip: number) => void;
};

/**
 * Хук для кнопки "Загрузить еще"
 * @example const [isAllItemsDownloaded, loadMore, resetPage] = useFetchOnButton();
 */
const useFetchOnButton = ({ loading, defaultSkip, defaultTake, values, count, fetch }: IUseFetchOnButtonArgs) => {
    const { coursesList } = useContext(SettingsContext);
    const filter = coursesList && coursesList.filter;
    const [take, setTake] = useState((filter && filter.take) || defaultTake);
    const [skip, setSkip] = useState((filter && filter.skip) || defaultSkip);
    const isAllItemsDownloaded = useMemo(() => count <= values.length, [count, values]);

    const resetPage = useCallback(() => {
        setTake((filter && filter.take) || defaultTake);
        setSkip((filter && filter.skip) || defaultSkip);
    }, [defaultSkip, defaultTake, filter, setTake, setSkip]);

    const loadMore = useCallback(() => {
        if (count > values.length && !loading) {
            fetch(take, skip + take);
            setSkip(skip + take);
        }
    }, [count, values, loading, setSkip, skip, take, fetch]);

    return [isAllItemsDownloaded, loadMore, resetPage] as const;
};

export default useFetchOnButton;
