import React from 'react';
import Star, { StarColorEnum, StarFillingEnum } from './Star';

type IStarRatingProps = {
    totalStars?: number;
    onSelectedChange?: (rating: number) => void;
    starsSelected: number;
    classname?: string;
    starsCustomStyles?: React.CSSProperties;
    isSelectionDisabled?: boolean;
    color?: StarColorEnum;
};

type IStarRatingState = {
    hoverStarsSelected: number;
};

class StarRating extends React.PureComponent<IStarRatingProps, IStarRatingState> {
    static defaultProps = {
        totalStars: 5,
    };

    state: IStarRatingState = {
        hoverStarsSelected: 0,
    };

    handleChange = starsSelected => !this.props.isSelectionDisabled && this.props.onSelectedChange(starsSelected);

    handleHoverChange = hoverStarsSelected => this.setState({ hoverStarsSelected });

    fillStar = (
        isHover: boolean,
        hoverStarsSelected: number,
        starsSelected: number,
        isSelectionDisabled: boolean,
        i: number,
    ): StarFillingEnum => {
        if (isHover && !isSelectionDisabled) {
            return i < hoverStarsSelected ? StarFillingEnum.filled : StarFillingEnum.empty;
        } else {
            if (0.25 < starsSelected - i && starsSelected - i < 0.75) return StarFillingEnum.halfFilled;
            return i < starsSelected ? StarFillingEnum.filled : StarFillingEnum.empty;
        }
    };

    render() {
        const {
            totalStars,
            starsSelected,
            classname,
            starsCustomStyles,
            isSelectionDisabled,
            color = StarColorEnum.red,
        } = this.props;
        const { hoverStarsSelected } = this.state;
        const isHover = hoverStarsSelected > 0;

        return (
            <div className={classname}>
                {Array(totalStars)
                    .fill(null)
                    .map((_, i) => (
                        <Star
                            key={i}
                            onClick={() => this.handleChange(i + 1)}
                            onMouseEnter={() => this.handleHoverChange(i + 1)}
                            onMouseLeave={() => this.handleHoverChange(0)}
                            fill={this.fillStar(isHover, hoverStarsSelected, starsSelected, isSelectionDisabled, i)}
                            color={color}
                            customStyles={starsCustomStyles}
                        />
                    ))}
            </div>
        );
    }
}

export default StarRating;
