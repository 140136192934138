import c from 'classnames';
import CenterLoader from 'core/components/common/CenterLoader';
import { LinksConsumer } from 'core/components/linksContext';
import { button, buttonAccent, buttonShadow } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { numEnding } from 'core/utils/string';
import React from 'react';
import { Order, Product, ProductForm } from 'site/graphql';

export type IPayProductForm = Pick<
    ProductForm,
    'id' | 'price' | 'paymentStatus' | 'title' | 'maxOrders' | 'showRemainingOrders' | 'ordersCount' | 'type'
> & {
    product: Pick<Product, 'id' | 'name'>;
    order?: Pick<Order, 'id'>;
};

type IPayButtonProps = {
    productForm: IPayProductForm;
    openPayModal(): void;
    deleteOrder(): void;
    loading: boolean;
} & WithStyles<typeof styles>;

class PayButton extends React.Component<IPayButtonProps> {
    hasPlaceLimit() {
        const { productForm } = this.props;

        return productForm.maxOrders !== 0 && productForm.showRemainingOrders;
    }

    getFreePlaces() {
        const { productForm } = this.props;

        const count = productForm.maxOrders - productForm.ordersCount;

        return count < 0 ? 0 : count;
    }

    renderNoPlaces() {
        const { classes, productForm, deleteOrder } = this.props;

        return (
            <div className={classes.wrapper}>
                <span className={classes.content}>К сожалению, места закончились</span>
                {productForm.order && (
                    <button className={c(classes.later, classes.content)} onClick={deleteOrder}>
                        Отмена
                    </button>
                )}
            </div>
        );
    }

    renderTitle() {
        const { productForm } = this.props;
        let title = '';

        if (productForm.price > 0) {
            title = 'Записаться';
        } else {
            title = 'Присоединиться';
        }

        if (this.hasPlaceLimit()) {
            const count = this.getFreePlaces();
            title += ` (осталось ${count} ${numEnding(count, ['место', 'места', 'мест'])})`;
        }

        return title;
    }

    render() {
        const { classes, productForm, openPayModal, deleteOrder, loading } = this.props;

        if (loading) {
            return <CenterLoader disableMinHeight />;
        }

        if (productForm.paymentStatus === 'NOT_AUTHORIZED' && this.hasPlaceLimit() && this.getFreePlaces() < 1) {
            return this.renderNoPlaces();
        }

        switch (productForm.paymentStatus) {
            case 'NOT_AUTHORIZED': {
                return (
                    <div className={classes.wrapper}>
                        <button className={c(classes.pay, classes.content)} onClick={openPayModal}>
                            {this.renderTitle()}
                        </button>
                    </div>
                );
            }
            case 'NEVER': {
                return (
                    <div className={classes.wrapper}>
                        <button className={c(classes.pay, classes.content)} onClick={openPayModal}>
                            {this.renderTitle()}
                        </button>
                    </div>
                );
            }
            case 'CART': {
                return (
                    <div className={classes.wrapper}>
                        <button className={c(classes.pay, classes.content)} onClick={openPayModal}>
                            Оплатить
                        </button>
                        <button className={c(classes.later, classes.content)} onClick={deleteOrder}>
                            Отмена
                        </button>
                    </div>
                );
            }
            case 'ALREADY': {
                return (
                    <div className={classes.wrapper}>
                        <LinksConsumer>
                            {links => {
                                const url = productForm.product
                                    ? links.accountProductDetail.url(productForm.product.id)
                                    : links.accountCourses.url();
                                const Link = productForm.product
                                    ? links.accountProductDetail.Link
                                    : links.accountCourses.Link;
                                return (
                                    <Link to={url} className={c(classes.pay, classes.content)}>
                                        Смотреть в Личном Кабинете
                                    </Link>
                                );
                            }}
                        </LinksConsumer>
                    </div>
                );
            }
            case 'EXPIRED': {
                return (
                    <div className={classes.wrapper}>
                        <p>Срок доступа истек</p>
                        <button className={c(classes.pay, classes.content)} onClick={openPayModal}>
                            {this.renderTitle()}
                        </button>
                    </div>
                );
            }
            case 'BLOCKED': {
                return (
                    <div className={classes.wrapper}>
                        <span className={classes.content}>Доступно только участникам курса</span>
                    </div>
                );
            }
            case 'PROCESSING': {
                return (
                    <div className={classes.wrapper}>
                        <span className={classes.content}>
                            Платеж не совершен, вы можете нажать кнопку Отмена и выбрать другой способ оплаты
                        </span>
                        <button className={c(classes.later, classes.content)} onClick={deleteOrder}>
                            Отмена
                        </button>
                    </div>
                );
            }
            case 'NO_PLACES': {
                return this.renderNoPlaces();
            }
            case 'NOT_AVAILABLE': {
                return (
                    <div className={classes.wrapper}>
                        <span className={classes.content}>Форма недоступна для покупки</span>
                    </div>
                );
            }
            default: {
                return (
                    <div className={classes.wrapper}>
                        <span className={classes.content}>Курс не найден</span>
                    </div>
                );
            }
        }
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        content: {
            width: '100%',
            textAlign: 'center',
            padding: 0.75 + 'rem',
            lineHeight: 1,
            boxSizing: 'border-box',
        },
        pay: {
            ...button(theme),
            ...buttonAccent(theme),
            ...buttonShadow,
            fontSize: 1 + 'rem',
            padding: 0.75 + 'rem',
            fontWeight: 700,
        },
        later: {
            ...button(theme),
            ...buttonShadow,
            marginTop: 0.5 + 'rem',
            fontSize: 1 + 'rem',
            padding: 0.75 + 'rem',
            fontWeight: 700,
        },
    });

export default injectSheet(styles, 'PayButton')(PayButton);
