import { ApolloError, gql } from 'apollo-boost';
import c from 'classnames';
import { useLinks } from 'core/components/linksContext';
import { showSnackbar } from 'core/components/snackbar';
import { button, buttonAccent } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { getErrors } from 'core/utils/apollo';
import React from 'react';
import { useAuth } from 'site/components/auth/AuthProvider';
import { SubscriptionPublicTypeEnum, useCreateSubscriptionMutation } from 'site/graphql';

gql`
    mutation CreateSubscription($returnUrl: String!, $type: SubscriptionPublicTypeEnum!) {
        createSubscription(returnUrl: $returnUrl, type: $type) {
            id
            confirmationUrl
        }
    }
`;

type ISubscribeButtonProps = {
    label?: string;
    subLabel?: string;
    type: SubscriptionPublicTypeEnum;
    className?: string;
} & WithStyles<typeof styles>;

const SubscribeButton: React.FC<ISubscribeButtonProps> = ({
    classes,
    label = 'Оформить подписку',
    subLabel,
    type,
    className,
}) => {
    const { isLoggedIn, openAuthModal } = useAuth();
    const links = useLinks();

    const [createSubscription] = useCreateSubscriptionMutation({
        onCompleted: data => {
            const confirmationUrl = data.createSubscription.confirmationUrl;

            if (confirmationUrl) {
                window.location.href = confirmationUrl;
            }
        },
        onError: (error: ApolloError) => showSnackbar({ snackbarErrors: getErrors(error) }),
    });

    const handleClick = isLoggedIn
        ? () =>
              createSubscription({
                  variables: { type, returnUrl: window.location.origin + links.paymentSuccess.url('subscription') },
              })
        : () => openAuthModal('login');

    return (
        <button onClick={handleClick} className={c(classes.button, className)}>
            {label}
            {subLabel && (
                <>
                    <br />
                    <span className={classes.subLabel}>{subLabel}</span>
                </>
            )}
        </button>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        button: {
            ...button(theme),
            ...buttonAccent(theme),
            width: 100 + '%',
        },
        subLabel: {
            fontSize: 0.6 + 'rem',
            fontWeight: 'normal',
        },
    });

export default injectSheet(styles)(SubscribeButton);
