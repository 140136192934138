import { gql } from 'apollo-boost';
import { trackClickCancelButton, trackRemove } from 'core/tracking';
import React, { useCallback } from 'react';
import { useAuth } from 'site/components/auth/AuthProvider';
import { useDeleteOrderMutation } from 'site/graphql';
import PayButtonPresent, { IPayProductForm } from './PayButtonPresent';

gql`
    mutation DeleteOrder($order: OrderPublicDeletetInput!) {
        deleteOrder(order: $order) {
            id
            productForm {
                id
                paymentStatus
                order {
                    id
                }
            }
        }
    }
`;

type IPayButtonContainerProps = {
    productForm: IPayProductForm;
};

const PayButtonContainer: React.FC<IPayButtonContainerProps> = ({ productForm }) => {
    const { setPayProductFormId, setState } = useAuth();

    const orderId = (productForm && productForm.order && productForm.order.id) || 0;
    const [deleteOrder, { loading }] = useDeleteOrderMutation({
        variables: { order: { id: orderId } },
        onCompleted: () => {
            trackClickCancelButton();
            trackRemove({
                id: productForm.product.id,
                name: productForm.product.name,
                quantity: 1,
                variant: productForm.type,
            });
        },
    });

    const openPayModal = useCallback(() => {
        setState('login');
        setPayProductFormId(productForm.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productForm]);

    return (
        <PayButtonPresent
            productForm={productForm}
            openPayModal={openPayModal}
            loading={loading}
            deleteOrder={deleteOrder}
        />
    );
};

export default PayButtonContainer;
