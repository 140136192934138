import LoadMoreButton from 'core/components/LoadMoreButton';
import { column, container, pageHeader, row, title } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import SearchBox from 'site/components/search-box/SearchBox';
import useLecturersData from './hooks/useLecturersData';
import Lecturer from './Lecturer';

const isEven = (num: number) => !num || !(num % 2);

const LecturersList: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
    const [isAllItemsDownloaded, loadMore, fetch, lecturers, loading, search] = useLecturersData();

    return (
        <>
            <div className={classes.header}>
                <h1>О преподавателях</h1>
            </div>
            <div className={classes.container}>
                <div className={classes.search}>
                    <SearchBox
                        search={search}
                        onChange={search => fetch({ search })}
                        placeholder="Кого бы вы хотели найти?"
                    />
                </div>
                <div className={classes.content}>
                    {lecturers &&
                        lecturers.map((lecturer, i) => (
                            <div className={classes.lecturer} key={lecturer.id}>
                                {i !== 0 ? <hr className={classes.hr} /> : null}
                                <Lecturer lecturer={lecturer} mode="LIST" imageOrder={isEven(i + 1)} />
                            </div>
                        ))}
                </div>
                <LoadMoreButton
                    isAllItemsDownloaded={isAllItemsDownloaded}
                    isFetching={loading}
                    loadMore={loadMore}
                    values={lecturers}
                />
            </div>
        </>
    );
};

export const styles = (theme: ITheme) =>
    createStyles({
        header: {
            ...pageHeader(theme),
        },
        container: {
            ...container(theme),
            paddingTop: 1 + 'rem',
        },
        search: {
            marginBottom: '1.5rem',
        },
        content: {
            ...row(),
        },
        lecturer: {
            ...column(),
        },
        hr: {
            height: 0,
            border: 'none',
            borderTop: '1px solid ' + theme.colors.lightGray,
            margin: '1.5rem 0',
        },
        errorTitle: {
            ...title({ size: 'h1' }),
        },
    });

export default injectSheet(styles, 'LecturersList')(LecturersList);
