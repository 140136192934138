import { graphqlApiSite } from 'core/api/graphql';
import Modal from 'core/components/Modal';
import { button, buttonAccent, buttonBlack, ieFix } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { addDaysToDate, isMobile } from 'core/utils';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FeedbackToProduct } from 'site/graphql';
import { useAuth } from '../auth/AuthProvider';
import useFeedbackButtonData from './hooks/useFeedbackButtonData';
import { addToLocalStorage, removeObsoleteLocalStorage } from './local-storage-utils';
import StarRating from './StarRating';

type IFeedbackButtonProps = {
    productId: number;
    accentButton?: boolean;
    openModalIfFeedbackNotExist?: boolean;
} & WithStyles<typeof styles>;

const FeedbackButton: React.FC<IFeedbackButtonProps> = ({
    classes,
    productId,
    accentButton,
    openModalIfFeedbackNotExist,
}) => {
    const [product, createFeedback, updateFeedback, refetchProduct] = useFeedbackButtonData(productId);
    const { isLoggedIn } = useAuth();

    const [isModalOpen, setIsModalOpen] = useState(
        openModalIfFeedbackNotExist && !product.hasFeedback && !removeObsoleteLocalStorage()[productId],
    );
    const [isMyFeedbackFetching, setIsMyFeedbackFetching] = useState(false);
    const [description, setDescription] = useState('');
    const [rating, setRating] = useState(0);
    const [showRemindMeLater, setShowRemindMeLater] = useState(
        openModalIfFeedbackNotExist && !product.hasFeedback && !removeObsoleteLocalStorage()[productId],
    );

    useEffect(() => {
        setIsMyFeedbackFetching(true);

        const query = `{
            feedbackToProduct(productId: ${productId}) {
                description
                rating
            }
        }`;

        graphqlApiSite<{ feedbackToProduct: FeedbackToProduct }>(query, null)
            .then(({ data }) => {
                setDescription(data.feedbackToProduct.description);
                setRating(data.feedbackToProduct.rating);
                setIsMyFeedbackFetching(false);
            })
            .catch(() => setIsMyFeedbackFetching(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        refetchProduct();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    const onCloseModal = useCallback(() => {
        setIsModalOpen(false);
        setShowRemindMeLater(false);
    }, []);
    const onOpenModal = useCallback(() => setIsModalOpen(true), []);
    const onDescriptionChange = useCallback(e => setDescription(e.target.value), []);
    const onRatingChange = useCallback((rating: number) => setRating(rating), []);

    const onUpdateFeedback = useCallback(() => {
        const isCreate = !product.hasFeedback;

        isCreate ? createFeedback(description, rating) : updateFeedback(description, rating);
        onCloseModal();
    }, [onCloseModal, createFeedback, updateFeedback, product, description, rating]);

    const handleRemindMeLaterClick = useCallback(() => {
        onCloseModal();
        addToLocalStorage(productId, addDaysToDate(new Date(), 7));
    }, [onCloseModal, productId]);

    const renderButton = useCallback(() => {
        const { hasAnyPurchasedForm, hasFeedback } = product;

        if (isMyFeedbackFetching) {
            return null;
        }

        if (hasFeedback) {
            return (
                <button onClick={onOpenModal} className={accentButton ? classes.buttonAccent : classes.button}>
                    Редактировать отзыв
                </button>
            );
        }

        if (hasAnyPurchasedForm && !hasFeedback) {
            return (
                <button onClick={onOpenModal} className={accentButton ? classes.buttonAccent : classes.button}>
                    Оставить отзыв
                </button>
            );
        }

        if (!hasAnyPurchasedForm && !hasFeedback) {
            return (
                <div className={classes.textOnlyPaid}>Отзыв оставить могут только пользователи, посетившие курс</div>
            );
        }

        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMyFeedbackFetching, product, accentButton, onOpenModal]);

    const isRatingCorrect = useMemo(() => rating > 0 && rating < 6, [rating]);

    return (
        <>
            {renderButton()}
            <Modal label="Оставить отзыв" open={isModalOpen} onClose={onCloseModal}>
                <div className={classes.modal}>
                    <div className={classes.modal__mainTitle}>Отзыв</div>
                    <div className={classes.modal__title}>Ваша оценка</div>
                    <StarRating
                        onSelectedChange={onRatingChange}
                        starsSelected={rating}
                        classname={classes.modal__rating}
                    />
                    <div className={classes.modal__title}>Ваш отзыв</div>
                    <textarea
                        className={classes.modal__textarea}
                        rows={isMobile() ? 10 : 20}
                        onChange={onDescriptionChange}
                        value={description || ''}
                    />
                    <div className={classes.footer}>
                        <div className={classes.remindLaterWrapper}>
                            {showRemindMeLater && (
                                <span className={classes.remindLater} onClick={handleRemindMeLaterClick}>
                                    Напомнить позже
                                </span>
                            )}
                        </div>
                        <button
                            className={classes.modal__button}
                            disabled={!isRatingCorrect || !description}
                            onClick={onUpdateFeedback}
                        >
                            {product.hasFeedback ? 'Редактировать' : 'Оставить'} отзыв
                        </button>
                        <div style={{ flex: 1 }} />
                    </div>
                </div>
            </Modal>
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        button: {
            ...button(theme),
            ...buttonBlack(theme),
            width: 100 + '%',

            '&:focus': {
                outline: 'none',
            },
        },
        buttonAccent: {
            ...button(theme),
            ...buttonAccent(theme),
            width: 100 + '%',
            marginTop: 1 + 'rem',

            '&:focus': {
                outline: 'none',
            },
        },
        modal: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: 1 + 'rem',
            width: theme.breakpoints.values.lg,

            ...ieFix({ display: 'block' }),

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 2rem)', // весь экран - 2*padding
                height: 'calc(100vh - 2rem)', // весь экран - 2*padding
                justifyContent: 'center',
            },
        },
        modal__button: {
            ...button(theme),
            ...buttonAccent(theme),
            alignSelf: 'center',

            '&:disabled': {
                backgroundColor: theme.colors.footerBg,
                color: theme.colors.footerText,
            },
        },
        textOnlyPaid: {
            textAlign: 'center',
        },
        modal__mainTitle: {
            marginBottom: 0.75 + 'rem',
            fontSize: 1.6 + 'rem',
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        modal__title: {
            marginBottom: 0.7 + 'rem',
            fontSize: 1 + 'rem',
            fontWeight: 600,
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        modal__rating: {
            marginBottom: 0.7 + 'rem',
            lineHeight: 0,
            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        modal__textarea: {
            resize: 'none',
            padding: 1 + 'rem',
            fontSize: 0.9 + 'rem',

            ...ieFix({ display: 'block', width: 'calc(-2rem + 100%)' }),
        },
        footer: {
            display: 'flex',
            marginTop: 1.5 + 'rem',

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column-reverse',
            },
        },
        remindLaterWrapper: {
            flex: 1,
            alignSelf: 'center',

            [theme.breakpoints.down('xs')]: {
                marginTop: 1 + 'rem',
            },
        },
        remindLater: {
            fontWeight: 'bold',
            color: theme.colors.black,
            borderBottom: `2px solid ${theme.colors.black}`,
            cursor: 'pointer',
            fontSize: 0.9 + 'rem',
        },
    });

export default injectSheet(styles, 'FeedbackButton')(FeedbackButton);
