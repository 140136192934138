import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';

export enum StarColorEnum {
    'red',
    'black',
}

export enum StarFillingEnum {
    'empty',
    'halfFilled',
    'filled',
}

const getStarFilling = (color: StarColorEnum, fill: StarFillingEnum) => {
    if (color === StarColorEnum.black) {
        switch (fill) {
            case StarFillingEnum.halfFilled:
                return 'blackStarHalfFilled';
            case StarFillingEnum.filled:
                return 'blackStarFilled';
            default:
                return 'blackStar';
        }
    } else {
        switch (fill) {
            case StarFillingEnum.halfFilled:
                return 'starHalfFilled';
            case StarFillingEnum.filled:
                return 'starFilled';
            default:
                return 'star';
        }
    }
};

type IStarProps = {
    fill?: StarFillingEnum;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    customStyles?: React.CSSProperties;
    color?: StarColorEnum;
} & WithStyles<typeof styles>;

const Star: React.StatelessComponent<IStarProps> = ({
    fill,
    onClick,
    onMouseEnter,
    onMouseLeave,
    classes,
    customStyles,
    color,
}) => (
    <div
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        className={c(classes.star, {
            [classes.starFilled]: getStarFilling(color, fill) === 'starFilled',
            [classes.starHalfFilled]: getStarFilling(color, fill) === 'starHalfFilled',
            [classes.blackStar]: getStarFilling(color, fill) === 'blackStar',
            [classes.blackStarHalfFilled]: getStarFilling(color, fill) === 'blackStarHalfFilled',
            [classes.blackStarFilled]: getStarFilling(color, fill) === 'blackStarFilled',
        })}
        onClick={onClick}
        style={customStyles}
    />
);

const styles = createStyles({
    star: {
        display: 'inline-block',
        cursor: 'pointer',
        height: 27,
        width: 27,
        marginRight: 25,
        transition: 'background-image 0.2s ease-out',
        backgroundImage: `url(${require('./images/star-red.png')})`,
        backgroundSize: 'cover',
    },
    starFilled: {
        backgroundImage: `url(${require('./images/star-red-filled.png')})`,
    },
    starHalfFilled: {
        backgroundImage: `url(${require('./images/star-red-half-filled.png')})`,
    },
    blackStar: {
        backgroundImage: `url(${require('./images/star-black.png')})`,
    },
    blackStarFilled: {
        backgroundImage: `url(${require('./images/star-black-filled.png')})`,
    },
    blackStarHalfFilled: {
        backgroundImage: `url(${require('./images/star-black-half-filled.png')})`,
    },
});

export default injectSheet(styles)(Star);
