import { LinksConsumer } from 'core/components/linksContext';
import { column } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';

const styles = createStyles({
    empty: {
        ...column(),
    },
});

const DefaultNotFound: React.StatelessComponent<WithStyles<typeof styles>> = ({ classes }) => (
    <p className={classes.empty}>
        Пока что здесь ничего нет. <br />
        <LinksConsumer>
            {links => (
                <links.courseList.Link to={links.courseList.url()}>
                    Перейти к выбору других курсов
                </links.courseList.Link>
            )}
        </LinksConsumer>
    </p>
);

export default injectSheet(styles)(DefaultNotFound);
