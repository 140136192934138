import { LinksConsumer } from 'core/components/linksContext';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatToLocalDayMonthYearDot } from 'core/utils';
import React from 'react';
import { FeedbackToProduct, Product } from 'site/graphql';

type IReviewProductSectionProps = {
    feedback: Pick<FeedbackToProduct, 'id' | 'date'> & {
        product?: Pick<Product, 'id' | 'name'>;
    };
} & WithStyles<typeof styles>;

const ReviewProductSection: React.FC<IReviewProductSectionProps> = ({ classes, feedback }) => {
    return (
        <>
            <div>Отзыв от {formatToLocalDayMonthYearDot(feedback.date)} к курсу</div>
            <LinksConsumer>
                {links => (
                    <links.productDetail.Link
                        to={links.productDetail.url(feedback.product.id)}
                        className={classes.productLink}
                    >
                        {feedback.product.name}
                    </links.productDetail.Link>
                )}
            </LinksConsumer>
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        productLink: {
            color: theme.colors.black,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            textDecoration: 'none',
        },
    });

export default injectSheet(styles)(ReviewProductSection);
