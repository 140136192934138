import useToggle from 'core/hooks/useToggle';
import { button, buttonAccent } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import useSubscriptions from '../auth/hooks/useSubscriptions';
import SubscriptionModal from './SubscriptionModal';

type ISubscriptionBlockProps = {
    label?: string;
} & WithStyles<typeof styles>;

const SubscriptionBlock: React.FC<ISubscriptionBlockProps> = ({ classes, label }) => {
    const [toggle, { offToggle, onToggle }] = useToggle();
    const { hasSubscription, hasSubscriptionFreeCourses } = useSubscriptions();
    const hasAnySubscription = hasSubscription || hasSubscriptionFreeCourses;

    if (hasSubscription) {
        return null;
    }

    return (
        <>
            {label && <p className={classes.subscribeText}>{label}</p>}
            <button className={classes.subscribeButton} onClick={onToggle}>
                Оформить подписку
            </button>
            <SubscriptionModal isOpen={toggle} onClose={offToggle} hasSubscription={hasAnySubscription} />
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        subscribeText: {
            fontSize: 14,
            lineHeight: 14 + 'px',
            marginBottom: 1 + 'rem',
            marginTop: 1 + 'rem',
            textAlign: 'center',
        },
        subscribeButton: {
            ...button(theme),
            ...buttonAccent(theme),
            fontSize: 14,
            padding: '12px 16px',
            display: 'block',
            margin: '0 auto',
            marginTop: 1 + 'rem',
        },
    });

export default injectSheet(styles)(SubscriptionBlock);
