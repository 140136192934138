import c from 'classnames';
import { IControlProps } from 'core/components/carousel';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';

const styles = createStyles({
    pagination: {
        position: 'relative',
        margin: 0,
        top: 40,
        padding: 0,
    },
    paginationItem: {
        display: 'inline-block',
        position: 'relative',
        width: 10,
        height: 10,
        borderRadius: '50%',
        margin: 5,
        cursor: 'pointer',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: '1px solid #cdcdcd',
            borderRadius: '50%',
        },
    },
    paginationItemAcitve: {
        background: '#cdcdcd',
    },
});

export class PagingDots extends React.Component<IControlProps & WithStyles<typeof styles>> {
    getIndexes(count, inc) {
        const arr = [];
        for (let i = 0; i < count; i += inc) {
            arr.push(i);
        }
        return arr;
    }

    render() {
        const { slideCount, slidesToScroll, currentSlide, goToSlide, classes } = this.props;
        const indexes = this.getIndexes(slideCount, slidesToScroll);

        return (
            <div className={classes.pagination}>
                {indexes.map(index => {
                    return (
                        <span
                            key={index}
                            className={c(classes.paginationItem, {
                                [classes.paginationItemAcitve]: currentSlide === index,
                            })}
                            onClick={goToSlide.bind(null, index)}
                        />
                    );
                })}
            </div>
        );
    }
}

export default injectSheet(styles)(PagingDots);
