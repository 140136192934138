import {
    ProductForm,
    ProductFormPublicTypeEnum,
    Seminar,
    Webinar,
    Certificate,
    EventRecord,
    Material,
    VideoCourse,
} from 'site/graphql';
import { isFilledArray } from '../fp';
import { orderBySeminarStartDay, orderByWebinarStartDay } from './orderProductForms';
import {
    getLastCourseDateWithDuration,
    getLastSeminarDateWithDuration,
    getLastWebinarDateWithDuration,
    IGetLastCourseDateWithDurationProductForm,
    IGetLastSeminarDateWithDurationProductForm,
    IGetLastWebinarDateWithDurationProductForm,
} from './productForms';

export const filterIsNonSingle = <T extends Pick<ProductForm, 'isNonSingle'>>(productForms: T[]) =>
    productForms.filter(pf => !pf.isNonSingle);

export type IFilterOffersProductForm = { withMeInSetForms?: Pick<ProductForm, 'id'>[] } & Pick<ProductForm, 'type'>;
export const filterOffers = <T extends IFilterOffersProductForm>(productForms: T[]) =>
    productForms.filter(pf => pf.type === ProductFormPublicTypeEnum.Offer && isFilledArray(pf.withMeInSetForms));

export const filterFuturesCoursesAndStarted = <T extends IGetLastCourseDateWithDurationProductForm>(
    productForms: T[],
) => productForms.filter(pf => getLastCourseDateWithDuration(pf) > new Date());

export const nearestOffersAndStarted = <T extends IFilterOffersProductForm & IGetLastCourseDateWithDurationProductForm>(
    productForms: T[],
) => filterFuturesCoursesAndStarted(filterOffers(productForms));

export type IFilterSeminarsProductForm = { seminars: Pick<Seminar, 'id'>[] } & Pick<ProductForm, 'type'>;
export const filterSeminars = <T extends IFilterSeminarsProductForm>(productForms: T[]) =>
    productForms.filter(pf => pf.type === ProductFormPublicTypeEnum.Seminar && isFilledArray(pf.seminars));

export const filterFuturesSeminarsAndStarted = <T extends IGetLastSeminarDateWithDurationProductForm>(
    productForms: T[],
) => productForms.filter(pf => getLastSeminarDateWithDuration(pf) > new Date());

/**
 * Фильтрует формы ближайших семинаров и уже начавшихся но еще не закончившихся
 * Формы сортируются по ближайшему дню в форме
 */
export const nearestSeminarsAndStarted = <
    T extends IFilterSeminarsProductForm & IGetLastSeminarDateWithDurationProductForm
>(
    productForms: T[],
) => {
    const seminarForms = filterSeminars(productForms);
    const futureSeminarForms = filterFuturesSeminarsAndStarted(seminarForms);

    return orderBySeminarStartDay(futureSeminarForms);
};

export type IFilterWebinarsProductForm = { webinars: Pick<Webinar, 'id'>[] } & Pick<ProductForm, 'type'>;
export const filterWebinars = <T extends IFilterWebinarsProductForm>(productForms: T[]) =>
    productForms.filter(pf => pf.type === ProductFormPublicTypeEnum.Webinar && isFilledArray(pf.webinars));

export const filterFuturesWebinarsAndStarted = <T extends IGetLastWebinarDateWithDurationProductForm>(
    productForms: T[],
) => productForms.filter(pf => getLastWebinarDateWithDuration(pf) > new Date());

/**
 * Фильтрует формы ближайших вебинаров и уже начавшихся но еще не закончившихся
 * Формы сортируются по ближайшему дню в форме
 */
export const nearestWebinarsAndStarted = <
    T extends IFilterWebinarsProductForm & IGetLastWebinarDateWithDurationProductForm
>(
    productForms: T[],
) => {
    const webinarForms = filterWebinars(productForms);
    const futureWebinarForms = filterFuturesWebinarsAndStarted(webinarForms);

    return orderByWebinarStartDay(futureWebinarForms);
};

export type IFilterCertificatesProductForm = { certificates: Pick<Certificate, 'id'>[] } & Pick<ProductForm, 'type'>;
export const filterCertificates = <T extends IFilterCertificatesProductForm>(productForms: T[]) =>
    productForms.filter(pf => pf.type === ProductFormPublicTypeEnum.Certificate && isFilledArray(pf.certificates));

export type IFilterMaterialsProductForm = { materials: Pick<Material, 'id'>[] } & Pick<ProductForm, 'type'>;
export const filterMaterials = <T extends IFilterMaterialsProductForm>(productForms: T[]) =>
    productForms.filter(pf => pf.type === ProductFormPublicTypeEnum.Material && isFilledArray(pf.materials));

export type IFilterEventRecordsProductForm = { records: Pick<EventRecord, 'id'>[] } & Pick<ProductForm, 'type'>;
export const filterRecords = <T extends IFilterEventRecordsProductForm>(productForms: T[]) =>
    productForms.filter(pf => pf.type === ProductFormPublicTypeEnum.EventRecord && isFilledArray(pf.records));

export type IFilterVideoCoursesProductForm = { videoCourses: Pick<VideoCourse, 'id'>[] } & Pick<ProductForm, 'type'>;
export const filterVideoCourses = <T extends IFilterVideoCoursesProductForm>(productForms: T[]) =>
    productForms.filter(pf => pf.type === ProductFormPublicTypeEnum.VideoCourse && isFilledArray(pf.videoCourses));

export type IExcludeOfferWithoutFormsProductForm = { withMeInSetForms: Pick<ProductForm, 'id'>[] } & Pick<
    ProductForm,
    'type'
>;
/** Исключение из списка OFFER без форм */
export const excludeOfferWithoutForms = <T extends IExcludeOfferWithoutFormsProductForm>(productForms: T[]) =>
    productForms.filter(pf => !(pf.type === ProductFormPublicTypeEnum.Offer && !isFilledArray(pf.withMeInSetForms)));
