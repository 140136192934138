import { column } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import { isFilledArray } from 'core/utils';
import React from 'react';

type ILoadMoreButtonProps = {
    isAllItemsDownloaded: boolean;
    isFetching: boolean;
    loadMore(): void;
    values: any[];
} & WithStyles<typeof styles>;

const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({
    classes,
    isAllItemsDownloaded,
    isFetching,
    loadMore,
    values,
}) => (
    <>
        {!isAllItemsDownloaded && !isFetching && (
            <button className={classes.loadMoreButton} onClick={loadMore}>
                Загрузить еще
            </button>
        )}

        {!isFilledArray(values) && isFetching && <p className={classes.preloader}>Загружаем...</p>}
        {isFilledArray(values) && isFetching && <p className={classes.preloader}>Загружаем ещё...</p>}
    </>
);

const styles = createStyles({
    loadMoreButton: {
        margin: '0 auto 15px',
        display: 'block',
        fontSize: 0.9 + 'rem',
        fontWeight: 700,
        border: '1px solid #cccccc',
        cursor: 'pointer',
        background: 'transparent',
        width: 150,
        height: 50,
    },
    '@keyframes loadingFade': {
        from: { opacity: 0 },
        to: { opacity: 1 },
    },
    preloader: {
        ...column(),
        'text-align': 'center',
        fontSize: 1.5 + 'rem',
        color: 'rgba(0, 0, 0, 0.5)',
        margin: 0,
        marginBottom: 15,
        height: 50,
        lineHeight: 50 + 'px',
        animation: '$loadingFade 0.5s ease infinite alternate',
    },
});

export default injectSheet(styles)(LoadMoreButton);
