import injectSheet, { createStyles } from 'core/style/injectSheet';
import { IStyledComponentProps, ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import compose from 'recompose/compose';
import withStateHandlers from 'recompose/withStateHandlers';
import CenterLoader from './CenterLoader';

export interface IExpandCardsProps<TItem = any> {
    children: (item: TItem, i: number) => React.ReactNode;
    items: TItem[];
    totalItemsCount?: number;
    isOpen: boolean;
    collapse: () => void;
    expand: () => void;
    expandOnly?: boolean;
    linkTitle?: string;
    linkTitleCollapse?: string;
    collapseCount?: number;
    isFetching?: boolean;
}

export function ExpandCards<TItem>({
    classes,
    children,
    items,
    totalItemsCount,
    isOpen,
    collapse,
    expand,
    expandOnly = false,
    linkTitle = 'Подробнее',
    linkTitleCollapse = 'Скрыть',
    collapseCount = 2,
    isFetching,
}: IExpandCardsProps<TItem> & WithStyles<typeof styles>) {
    let link = null;
    const itemsCount = totalItemsCount || items.length;

    if (expandOnly) {
        link = !isOpen && itemsCount > collapseCount && (
            <div className={classes.buttonWrapper}>
                <button className={classes.button} onClick={isOpen ? collapse : expand}>
                    {linkTitle}
                </button>
            </div>
        );
    } else {
        link = itemsCount > collapseCount && (
            <div className={classes.buttonWrapper}>
                <button className={classes.button} onClick={isOpen ? collapse : expand}>
                    {isOpen ? linkTitleCollapse : linkTitle}
                </button>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            {(isOpen ? items : items.slice(0, collapseCount)).map((item, i) => children(item, i))}
            {isFetching ? <CenterLoader disableMinHeight /> : link}
        </div>
    );
}

type IExpandCardsStyleProps<T> = IExpandCardsProps<T> & WithStyles<typeof styles>;
export type IExpandCardsOuterProps<T = any> = Pick<
    IExpandCardsStyleProps<T>,
    'items' | 'children' | 'linkTitle' | 'linkTitleCollapse' | 'collapseCount' | 'totalItemsCount' | 'isFetching'
> &
    IStyledComponentProps<typeof styles>;

export const styles = (theme: ITheme) =>
    createStyles({
        root: {},
        buttonWrapper: {
            width: 100 + '%',
        },
        button: {
            fontSize: 0.9 + 'rem',
            fontWeight: 700,
            border: '1px solid #cccccc',
            cursor: 'pointer',
            background: 'transparent',
            height: 50,
            padding: '0 20px',
            color: theme.colors.textBase,

            [theme.breakpoints.down('xs')]: {
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
    });

export default compose<any, IExpandCardsOuterProps>(
    withStateHandlers(
        { isOpen: false },
        {
            expand: () => () => ({ isOpen: true }),
            collapse: () => () => ({ isOpen: false }),
        },
    ),
    injectSheet(styles, 'ExpandCards'),
)(ExpandCards);
