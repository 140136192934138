import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatToLocalDayMonthYearDot, formatToLocalTimeSeconds } from 'core/utils';
import React from 'react';

type ILastUpdateProps = {
    className?: string;
    lastUpdate: string;
} & WithStyles<typeof styles>;

const LastUpdate: React.FC<ILastUpdateProps> = ({ classes, lastUpdate, className }) => {
    if (!lastUpdate) {
        return null;
    }

    return (
        <p className={c(className, classes.root)}>
            <span className={classes.date}>Данные на {formatToLocalDayMonthYearDot(lastUpdate)}</span>
            <span className={classes.time}>Последнее обновление: {formatToLocalTimeSeconds(lastUpdate)}</span>
        </p>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        root: {
            margin: 0,
            lineHeight: 22 + 'px',
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.down('xs')]: {
                textAlign: 'center',
            },
        },
        date: {
            fontSize: 1 + 'rem',
            color: theme.colors.textBase,
        },
        time: {
            fontSize: 0.85 + 'rem',
            color: '#9D9D9D',
        },
    });

export default injectSheet(styles)(LastUpdate);
