import { HighLowSecurity } from 'site/graphql';

export const HIGH_LOW_SORT = {
    name: 'name',
    low: 'low',
    high: 'high',
    day: 'day',
    week: 'week',
    month: 'month',
    ytd: 'ytd',
    year: 'year',
    position: 'position',
    'low-percent': 'low-percent',
    'high-percent': 'high-percent',
} as const;

export type IHighLowSort = keyof typeof HIGH_LOW_SORT;

export const HIGH_LOW_SORT_FN: { [key in IHighLowSort]: (_: HighLowSecurity) => any } = {
    name: _ => _.name,
    low: _ => _.low,
    high: _ => _.high,
    day: _ => _.d1,
    week: _ => _.w1,
    month: _ => _.m1,
    ytd: _ => _.yTD,
    year: _ => _.y1,
    position: _ => _.position,
    'low-percent': _ => _.lowPercent,
    'high-percent': _ => _.highPercent,
};

export enum HighLowIndex {
    MoscowExchangesIndex = 'MoscowExchangesIndex',
    MoscowExchangeBlueChipIndex = 'MoscowExchangeBlueChipIndex',
    Index = 'Index',
    ETF = 'ETF',
    Top100 = 'Top100',
}

export const highLowIndexFilterOptions: { value: HighLowIndex; label: string }[] = [
    { value: HighLowIndex.MoscowExchangesIndex, label: 'Индекс МосБиржи' },
    { value: HighLowIndex.MoscowExchangeBlueChipIndex, label: 'Индекс Голубых фишек' },
    { value: HighLowIndex.Index, label: 'Индексы' },
    { value: HighLowIndex.ETF, label: 'ETF' },
    { value: undefined, label: 'Топ-100' },
];
