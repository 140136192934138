import { IControlProps } from 'core/components/carousel';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';
import ArrowLeftIcon from '../../../svg/arrow_left.svg';

const styles = createStyles({
    button: {
        display: 'inline-block',
        height: 1.1 + 'rem',
        width: 1.1 + 'rem',
        verticalAlign: 'middle',
        fill: '#fefefe',
        cursor: 'pointer',
        transform: 'rotate(180deg)',

        '&:hover': {
            fill: '#cccccc',
        },
    },
});

type INextButtonProps = {
    customStyles?: React.CSSProperties;
    darkenWhenBoundary?: boolean;
} & IControlProps &
    WithStyles<typeof styles>;

export class NextButton extends React.PureComponent<INextButtonProps> {
    handleClick = event => {
        event.preventDefault();
        this.props.nextSlide();
    };

    render() {
        const {
            classes,
            customStyles,
            darkenWhenBoundary = false,
            currentSlide,
            slidesToShow,
            slideCount,
        } = this.props;
        const boundary = currentSlide + slidesToShow === slideCount;
        const opacity = darkenWhenBoundary && boundary ? 0.1 : 1;
        const cursor = darkenWhenBoundary && boundary ? 'default' : 'pointer';

        return (
            <ArrowLeftIcon
                onClick={this.handleClick}
                className={classes.button}
                style={{ ...customStyles, opacity, cursor }}
            />
        );
    }
}

export default injectSheet(styles)(NextButton);
